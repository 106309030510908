import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const history = useHistory();
  const [name, setName] = useState([]);

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  useEffect(() => {
    axios
      .get(BASE_URL + `/api/users/profile`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "api-key": API_KEY,
          "client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        setName(response.data.data.full_name);
      });
  }, []);

  const handleLogout = () => {
    if (localStorage.getItem("loggedIn")) {
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");
      history.push("/auth/login");
    }
  };

  return (
    <>
      <Navbar
        className={classnames("mb-3 pb-6 bg-gradient-primary navbar-top navbar-expand")}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center mr-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames("pr-3 sidenav-toggler", {
                    active: sidenavOpen,
                  })}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="bg-white sidenav-toggler-line" />
                    <i className="bg-white sidenav-toggler-line" />
                    <i className="bg-white sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>

              <div>
                <h3 className="font-weight-bolder m-0 text-white" style={{ opacity: 0.5 }}>DASHBOARD</h3>
              </div>
            </Nav>

            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav toggler="#navbar-default">
                <DropdownToggle
                  className="nav-link pr-0"
                  color=""
                  tag="a"
                  href="#"
                >
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle mr-1">
                      <img
                        alt="..."
                        src={`https://ui-avatars.com/api/?name=${name}&background=5030b4&color=fff&format=svg`}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm text-white font-weight-bold">
                        {name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu className="mt-2" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      const role = localStorage.getItem("role");
                      if(role == 'admin') {
                        history.push(`/admin/profile/${name}`)
                      } else {
                        history.push(`/user/profile/${name}`)
                      }
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  {/* <DropdownItem
                    href="#pablo"
                    onClick={(e) => history.push("/admin/log")}
                  >
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem> */}
                  <DropdownItem
                    href="#pablo"
                    disabled
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    className="text-danger"
                    onClick={() => handleLogout()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
