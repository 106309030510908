import React, { useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import "@fontsource/nunito";
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function Profile({ match }) {
  const {
    params: { id },
  } = match;

  const [user, setUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(1);

  const getProfile = useCallback(() => {
    axios
      .get(BASE_URL + `/api/users/profile`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "api-key": API_KEY,
          "client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
  }, []);

  useEffect(() => {
    getProfile()
  }, []);

  return (
    <>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <Card className="w-100 border">
              {user && (
                <CardBody>
                  <Row className="d-flex align-items-center">
                    <Col xs="auto" className="">
                      <div className="avatar avatar-xl rounded-circle">
                        <i className="ni ni-single-02 display-2" />
                      </div>
                    </Col>

                    <Col className="w-100">
                      <Row
                        style={{ fontWeight: "bold" }}
                        className="text-capitalize d-flex align-items-center"
                      >
                        {user.full_name} <Badge className="ml-2 py-1 px-2 text-capitalize" size="small" color={user.role === "admin" ? "success" : "primary"} pill>{user.role}</Badge>
                      </Row>
                      <Row>{user.username}</Row>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>

          <Col xs="12">
            <Nav
              className="nav-fill mb-2 flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabIndex === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabIndex === 1,
                  })}
                  onClick={(e) => setTabIndex(1)}
                  href="#pablo"
                  role="tab"
                >
                  <i className="ni ni-circle-08 mr-2" />
                  Profile
                </NavLink>
              </NavItem>
            </Nav>

            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabIndex}>
                  <TabPane tabId="tabs1">
                    <Row>
                      <Col xs="8">
                        <h3 className="mb-0">Edit profile</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>

                    <Form>
                      <div className="mt-2">
                        {user && <EditProfile user={user} getProfile={getProfile} />}
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const EditProfile = ({ user, getProfile }) => {
  const notifyRef = useRef();
  const [fullName, setFullName] = useState(user.full_name);

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyRef.current.notificationAlert(options);
  };

  const handleSubmit = () => {
    axios
      .put(
        BASE_URL + `/api/users/profile`,
        {
          fullName
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "api-key": API_KEY,
            "client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        getProfile();
        notify("success", "Success", "Profile Successfully Updated");
      })
      .catch(err => {
        notify("danger", "Error", "Failed to Update Profile");
      });
  };

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              Full Name
            </label>

            <Input
              id="input-username"
              placeholder="Full Name"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <div style={{ marginBottom: "30px" }}>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: "#3AA76D",
                color: "white",
                border: "0px",
              }}
            >
              Save
            </Button>

            <Button
              color="danger"
              className="border-0"
              outline
              onClick={() => setFullName(user.full_name)}
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
