import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NotificationAlert from "react-notification-alert";

//router
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Logo from "assets/img/brand/datafuse-logo.svg";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function Login() {
  const notifyRef = useRef();
  let history = useHistory();

  const [focusedUsername, setfocusedUsername] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChanged = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChanged = ({ target }) => {
    setPassword(target.value);
  };

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        < >
          <div className="alert-text ml-2">
            <h3 className="alert-title text-white" data-notify="title">
              {title}
            </h3>
          </div>
          <p data-notify="message">{message}</p>
        </>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyRef.current.notificationAlert(options);
  };

  if (localStorage.getItem("loggedIn") !== null) {
    if(localStorage.getItem('role') === 'admin') {
      history.push("/admin");
    } else {
      history.push("/user");
    };
  }

  function handleClick() {
    const json = { username: email, password: password, action: "login" };
    axios
      .post(BASE_URL + `/api/auth/login`, json, {
        headers: {
          "api-key": API_KEY,
          "client-id": CLIENT_ID,
        },
      })
      .then((response) => {
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("role", response.data.role);

        if (localStorage.getItem("loggedIn") !== null) {
          if(response.data.role == 'admin') {
            history.push("/admin");
          } else {
            history.push("/user");
          }
        }
      })
      .catch((error) => {
        // console.error("There was an error Logging in!", error);
        notify("danger", "Error", error.response.data.message);
      });
  };

  useEffect(() => {
    // Update the document title using the browser API
  });

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Container className="mt-9 pb-5">
        <Row className="justify-content-center overflow-hidden">
          <Col xs="4" lg="4" className="d-flex align-items-center">
            <img src={Logo} className="img-center w-100" />
          </Col>

          {/* I hate writing css just for media queries, so here's my workaround */}
          <Col lg="1" className="d-none d-sm-block" />
          <Col lg="1" className="border-left d-none d-sm-block" />

          <Col xs="12" md="8" lg="6" className="mt-4">
            <div className="text-center text-muted mb-4">
              <small>Welcome to Datafuse License Dashboard</small>
            </div>
            <Form role="form">
              <FormGroup
                className={classnames("mb-3", {
                  focused: focusedUsername,
                })}
              >
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    // type="email"
                    onChange={handleEmailChanged}
                    value={email}
                    onFocus={() => setfocusedUsername(true)}
                    onBlur={() => setfocusedUsername(true)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={classnames({
                  focused: focusedPassword,
                })}
              >
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    onChange={handlePasswordChanged}
                    value={password}
                    onFocus={() => setfocusedPassword(true)}
                    onBlur={() => setfocusedPassword(true)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  style={{
                    backgroundColor: "#0eb7b9",
                    borderColor: "transparent",
                    color: "white",
                  }}
                  type="button"
                  onClick={handleClick}
                >
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
