import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

import NotificationAlert from "react-notification-alert";
import CustomTable from "components/Table";
import {
  Alert,
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function LicenseOwner() {
  const notifyRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState('');

  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [error, setError] = useState(null);

  // Create New Owner Fields
  const [fullName, setFullName] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();


  const columns = [
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "created_date",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span>
            {new Date(cellContent).toDateString()}
          </span>
        );
      },
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{new Date(cellContent).toDateString()}</span>;
      },
    }
  ];

  const toggle = () => setModal(!modal);

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        < >
          <div className="alert-text ml-2">
            <h3 className="alert-title text-white" data-notify="title">
              {title}
            </h3>
          </div>
          <p data-notify="message">{message}</p>
        </>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyRef.current.notificationAlert(options);
  };
  
  const handleTableChange = (type, pages) => {
    setLimit(pages.sizePerPage);
    setPage(pages.page);
  };

  const createNewOwner = useCallback(() => {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: `${BASE_URL}/api/auth/register`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "api-key": API_KEY,
        "client-id": CLIENT_ID,
      },
      data: {
        fullName,
        username,
        password
      }
    })
    .then(res => {
      setIsLoading(false);
      fetchTableData();
      notify("success", "Success", "Created new License Owner Succesfully");
      toggle();
    })
    .catch(err => {
      setIsLoading(false);
      // console.log('Error Creating new Owner = ', err.response.data);
      notify("danger", "Error", err.response.data.message);
    });
  }, [fullName, username, password]);

  const fetchTableData = useCallback(async () => {
    setIsLoading(true);
    return axios
      .get(
        BASE_URL +
        `/api/users?page=${page}&limit=${limit}&license_owner=${search}&is_admin=1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "api-key": API_KEY,
            "client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setTotal(response.data.total);
        setError("");
        setIsLoading(false);
        return response.data;
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response.data.message);
        setIsLoading(false);
      });
  }, [limit, page, search]);

  useEffect(() => {
    fetchTableData();
  }, [limit, page]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Container className="mt--6 mx-lg--2" fluid>
        <Row className="flex-row-reverse align-items-center mx-2 mx-lg-2">
          <div className="d-flex w-100">
            <p className="w-50 text-white font-weight-normal">
              <InputGroup className="w-100 mb-3">
                <Input
                  placeholder="Input License Username's Owner..."
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="success"
                    onClick={fetchTableData}
                    disabled={isLoading}
                  >
                    Search
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </p>
          </div>
        </Row>
      </Container>

      <Container fluid style={{ fontFamily: "Nunito" }}>
        <Row>
          <div className="col">
            <FileTable
              title="License Owner"
              columns={columns}
              toggle={toggle}
              data={data}
              page={page}
              limit={limit}
              total={total}
              handleTableChange={handleTableChange}
              error={error}
            />
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={modal}
        centered
      >
        <ModalHeader>
          <h3>Add New License Owner</h3>
        </ModalHeader>
        <ModalBody>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="Full Name"
              type="text"
              onChange={(e) => setFullName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="Username"
              type="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={toggle}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={createNewOwner}
            disabled={isLoading}
          >
            Create Owner
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const FileTable = (props) => {
  const {
    title,
    columns,
    toggle,
    data,
    page,
    limit,
    total,
    handleTableChange,
    error
  } = props;

  return (
    <>
      {error && (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fas fa-times-circle" />
          </span>{" "}
          <span className="alert-inner--text ml-1">
            <strong>Error: </strong>
            {error}
          </span>
        </Alert>
      )}
      <Card>
        <CardHeader className="border-0 d-flex justify-content-between">
          <h2 className="mb-0">{title}</h2>
          <Button
            color="primary"
            onClick={toggle}
          >
            Add New license Owner
          </Button>
        </CardHeader>
        <div className="table-responsive table-hover">
          {data && (
            <CustomTable
              data={data}
              page={page}
              sizePerPage={limit}
              columns={columns}
              totalSize={total}
              onTableChange={handleTableChange}
              rowStyle={{ cursor: "pointer" }}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default LicenseOwner;
