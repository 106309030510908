import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { Upload } from "react-iconly";
import NotificationAlert from "react-notification-alert";

import CustomTable from "components/Table";

import {
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function LicenseActivation() {
  const notifyRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [licenseData, setLicenseData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [username, setUsername] = useState('');
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [file, setFile] = useState();

  const columns = [
    {
      dataField: "serial_number",
      text: "Serial Number",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "license_type",
      text: "License Type",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "license_name",
      text: "License Name",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>;
      },
    },
    {
      dataField: "license_software",
      text: "License Software",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>;
      },
    },
    {
      dataField: "issued_date",
      text: "Issued Date",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{new Date(cellContent).toDateString()}</span>;
      },
    },
    {
      dataField: "end_date",
      text: "Expired Date",
      sort: true,
      formatter: (cellContent, row) => {
        if(cellContent === null) return <span>-</span>
        return <span>{new Date(cellContent).toDateString()}</span>;
      },
    },
    // {
    //   dataField: "user_license",
    //   text: "User License",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <span>{cellContent}</span>;
    //   },
    // },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span className={`p-2 rounded text-white font-weight-bold ${cellContent === 'Active' ? 'bg-green' : 'bg-red'}`}>
            {cellContent}
          </span>
        );
      },
    },
  ];

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        < >
          <div className="alert-text ml-2">
            <h3 className="alert-title text-white" data-notify="title">
              {title}
            </h3>
          </div>
          <p data-notify="message">{message}</p>
        </>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyRef.current.notificationAlert(options);
  };

  const activateLicense = useCallback(() => {
    setIsLoading(true);
    const data = new FormData();
    data.append('file', file);

    axios({
      method: 'POST',
      url: `${BASE_URL}/api/licenses/activate`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "api-key": API_KEY,
        "client-id": CLIENT_ID,
      },
      data
    })
    .then(res => {
      setIsLoading(false);
      getAllLicenses();
      notify("success", "Success", "License Activated");
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false);
      if(err.response && err.response.data) {
        notify("danger", "Error", err.response.data.message);
      };
      notify("danger", "Error", "Failed to Activate License");
    });
  }, [file]);

  const getAllLicenses = useCallback(() => {
    setIsLoading(true);
    return axios
      .get(
        BASE_URL +
        `/api/licenses/user-license?page=${page}&limit=${limit}&username=${username}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "api-key": API_KEY,
            "client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setLicenseData(response.data.data.ownerLicenses);
        setData(response.data.data.licencesDetail);
        setTotal(response.data.total);
        setError("");
        setIsLoading(false);
        // return response.data;
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response.data.message);
        setIsLoading(false);
      });
  }, [limit, page, username]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setLimit(sizePerPage);
    setPage(page);
  };

  useEffect(() => {
    getAllLicenses();
  // }, [getAllLicenses]);
  }, [page, limit]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />

      {
        localStorage.getItem('role') == 'admin'
        ? (
          <Container className="mt--6 pb-6 mx-lg--2" fluid>
            <Row className="flex-row-reverse align-items-center mx-2 mx-lg-2">
              <div className="d-flex w-100">
                <p className="w-50 text-white font-weight-normal">
                  <InputGroup className="w-100 mb-3">
                    <Input
                      placeholder="Input License Owner's Username..."
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="success"
                        onClick={getAllLicenses}
                        disabled={isLoading}
                      >
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </p>
              </div>
            </Row>
          </Container>
        )
        : <Container className="mt--6 pb-6 mx-lg--2 mb-6" fluid />
      }
      
      <Container className="mt--6 mx-lg--2" fluid style={{ fontFamily: "Nunito" }}>
        <div className="mx-2 mx-lg-2 pt-4 border bg-white rounded">
          <Row className="col-lg px-5">
            <div className="w-50">
                <h2>License</h2>
                <div className="d-flex mt-5">
                  <div className="w-50">
                    <h3 className="mb-0">Issued By</h3>
                    <p>{licenseData ? licenseData.issued_by : ''}</p>
                  </div>
                  <div>
                    <h3 className="mb-0">License Owner</h3>
                    <p>{licenseData ? licenseData.license_owner : ''}</p>
                  </div>
                </div>
            </div>

            <div className="w-50">
              <h2 className="mb-5">Upload License</h2>
              <div className="mt-4">
                <Input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  defaultValue={file}
                />
                <Button color="success" className="d-flex align-items-center mt-3" onClick={activateLicense}>
                  <Upload size="medium" color='#fff' className="mr-2" />
                  Upload & Authorize
                </Button>
              </div>
            </div>
          </Row>

          <div className="table-responsive table-hover mt-4">
            <CustomTable
              data={data}
              page={page}
              sizePerPage={limit}
              columns={columns}
              totalSize={total}
              onTableChange={handleTableChange}
              rowStyle={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </Container>
    </>
  );
}

export default LicenseActivation;
