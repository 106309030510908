import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import CustomTable from "components/Table";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function LicensePage() {
  const notifyRef = useRef();

  const [data, setData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [licenseType, setLicenseType] = useState();
  const [licenseOwner, setLicenseOwner] = useState();
  const [licenseName, setLicenseName] = useState();
  const [licenseSoftware, setLicenseSoftware] = useState();

  const columns = [
    {
      dataField: "license_detail.serial_number",
      text: "Serial Number",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "license_type",
      text: "Type",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span className={`p-2 rounded text-white font-weight-bold bg-green`}>
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "license_detail.license_software",
      text: "License Software",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>;
      },
    },
    // {
    //   dataField: "user_license",
    //   text: "User License",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return <span>{cellContent}</span>;
    //   },
    // },
    {
      dataField: "license_owner",
      text: "License Owner",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{cellContent}</>;
      },
    },
    {
      dataField: "issued_date",
      text: "Issued Date",
      sort: true,
      formatter: (cellContent, row) => {
        return <>{new Date(cellContent).toDateString()}</>;
      },
    },
    {
      dataField: "license_detail.status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span className={`p-2 rounded text-white font-weight-bold ${cellContent === 'Active' ? 'bg-green' : 'bg-red'}`}>
            {cellContent}
          </span>
        );
      },
    },
  ];

  const toggle = () => setModal(!modal);

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        < >
          <div className="alert-text ml-2">
            <h3 className="alert-title text-white" data-notify="title">
              {title}
            </h3>
          </div>
          <p data-notify="message">{message}</p>
        </>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notifyRef.current.notificationAlert(options);
  };

  const handleDownload = (data, owner, name, key) => {
    const jsonData = JSON.stringify(data);

    const a = document.createElement("a");
    a.href = `data:application/json;charset=utf-11,${jsonData}`;
    a.download = `${owner}-${name}-${key}.json`;
    a.click();
  };

  const createNewLicense = useCallback(() => {
    setIsLoading(true);
    axios({
      method: 'POST',
      url: `${BASE_URL}/api/licenses`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "api-key": API_KEY,
        "client-id": CLIENT_ID,
      },
      data: {
        licenseType,
        licenseOwner,
        licenseName,
        licenseSoftware
      }
    })
    .then(res => {
      setIsLoading(false);
      notify("success", "Success", "Created new License Owner Succesfully");
      handleDownload(res.data.data, res.data.data.license_owner, res.data.data.licenseDetail.license_name, res.data.data.licenseDetail.serial_number)
      fetchTableData();
      toggle();
      setLicenseName();
      setLicenseOwner();
      setLicenseSoftware();
      setLicenseType();
    })
    .catch(err => {
      // console.log('Error Creating new Owner = ', err.response.data);
      setIsLoading(false);
      notify("danger", "Error", err.response.data.message);
    });
  }, [licenseName, licenseOwner, licenseType]);

  const fetchTableData = useCallback(async () => {
    setIsLoading(true);
    return axios
      .get(
        BASE_URL +
        `/api/licenses?page=${page}&limit=${limit}&license_name=${search}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "api-key": API_KEY,
            "client-id": CLIENT_ID,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setTotal(response.data.total);
        setError("");
        setIsLoading(false);
        return response.data;
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
        setError(e.response.data.message);
        setIsLoading(false);
      });
  }, [limit, page, search]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setLimit(sizePerPage);
    setPage(page);
  };

  useEffect(() => {
    fetchTableData();
  // }, [fetchTableData]);
  }, [limit, page]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Container className="mt--6 mx-lg--2" fluid>
        <Row className="flex-row-reverse align-items-center mx-2 mx-lg-2">
          <div className="d-flex w-100">
            <p className="w-50 text-white font-weight-normal">
              <InputGroup className="w-100 mb-3">
                <Input
                  placeholder="Input License Software..."
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="success"
                    onClick={fetchTableData}
                    disabled={isLoading}
                  >
                    Search
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </p>
          </div>
        </Row>
      </Container>

      <Container fluid style={{ fontFamily: "Nunito" }}>
        <Row>
          <div className="col">
            <UserTable
              title="License"
              columns={columns}
              data={data}
              page={page}
              limit={limit}
              total={total}
              error={error}
              handleTableChange={handleTableChange}
              toggle={toggle}
            />
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={modal}
        centered
      >
        <ModalHeader>
          <h3>Add New License</h3>
        </ModalHeader>
        <ModalBody>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="License Owner Username"
              type="text"
              onChange={(e) => setLicenseOwner(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="License Name"
              type="text"
              onChange={(e) => setLicenseName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="w-100 mb-3">
            <Input
              placeholder="License Software"
              type="text"
              onChange={(e) => setLicenseSoftware(e.target.value)}
            />
          </InputGroup>
          <div className="my-4">
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
              <DropdownToggle caret className="px-3 d-flex align-items-center">
                <p className="m-0 mr-2">
                  {licenseType ? licenseType : 'Select License Type'}
                </p>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={false} onClick={() => setLicenseType('Annual')}>Annual</DropdownItem>
                <DropdownItem disabled={false} onClick={() => setLicenseType('Perpetual')}>Perpetual</DropdownItem>
                <DropdownItem disabled={false} onClick={() => setLicenseType('Monthly')}>Monthly</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={toggle}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={createNewLicense}
            disabled={isLoading}
          >
            Create Owner
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const UserTable = (props) => {
  const {
    title,
    columns,
    data,
    page,
    limit,
    total,
    error,
    handleTableChange,
    toggle
  } = props;

  return (
    <>
      {error && (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fas fa-times-circle" />
          </span>{" "}
          <span className="alert-inner--text ml-1">
            <strong>Error: </strong>
            {error}
          </span>
        </Alert>
      )}
      <Card>
        <CardHeader className="border-0 d-flex justify-content-between">
          <h2 className="mb-0">{title}</h2>
          <Button
            color="primary"
            onClick={toggle}
          >
            Add New license
          </Button>
        </CardHeader>
        <div className="table-responsive table-hover">
          {data && (
            <CustomTable
              data={data}
              page={page}
              sizePerPage={limit}
              columns={columns}
              totalSize={total}
              onTableChange={handleTableChange}
              rowStyle={{ cursor: "pointer" }}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default LicensePage;
