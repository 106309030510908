import License from "views/pages/License";
import Profile from "views/pages/Profile";
import CustomLogin from "views/pages/login";
import Profiles from "views/pages/examples/Profile";
import LicenseOwner from "views/pages/LicenseOwner";
import LicenseActivation from "views/pages/LicenseActivation";

import { Unlock, Document, ShieldDone } from "react-iconly";


const routes = [
  {
    path: "/license-activation",
    name: "License Activation",
    icon: Unlock,
    component: LicenseActivation,
    layout: "/admin",
  },
  {
    path: "/licenses",
    name: "License",
    icon: Document,
    component: License,
    layout: "/admin",
  },
  {
    path: "/license-owner",
    name: "License Owner",
    icon: ShieldDone,
    component: LicenseOwner,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "",
    component: CustomLogin,
    layout: "/auth",
  },
  {
    path: "/profile/:id",
    name: "",
    miniName: "",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/profiles",
    name: "",
    miniName: "",
    component: Profiles,
    layout: "/admin",
  },
];

export default routes;
