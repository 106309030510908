import React, { useState } from "react";

import { Button, Input, InputGroupAddon, InputGroup } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import classnames from "classnames";

const { SearchBar } = Search;

const MySearch = (props) => {
  const [input, setInput] = useState("");

  const handleClick = () => {
    props.searchState(input);
    props.onSearch(input);
  };

  return (
    <InputGroup className="mt-1">
      <Input
        placeholder={props.placeholder}
        className="form-control form-control-sm"
        type="text"
        onChange={(e) => setInput(e.target.value)}
      />
      <InputGroupAddon addonType="append">
        <Button
          color="primary"
          outline
          type="button"
          size="sm"
          onClick={handleClick}
        >
          Search
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

const initialOptions = {
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: true,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
};

const pageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <li
      className={classnames(
        "page-item",
        active && "active",
        disabled && "disabled "
      )}
      style={disabled ? { cursor: "not-allowed" } : null}
    >
      <a href="#" onClick={handleClick} className="page-link">
        {page === "<<" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === "<" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === ">" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {page === ">>" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-50 w-50"
            viewBox="0 0 20 20"
            fill="currentColor"
            style={disabled ? { opacity: 0.5 } : null}
          >
            <path
              fillRule="evenodd"
              d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {typeof page !== "string" && page}
      </a>
    </li>
  );
};

const CustomTable = (props) => {
  const {
    searchState,
    data,
    columns,
    onTableChange,
    page,
    sizePerPage,
    totalSize,
    rowEvents,
    rowStyle,
  } = props;

  return (
    <>
      <ToolkitProvider
        data={data}
        keyField="timestamp"
        columns={columns}
        search
      >
        {(props) => (
          <div className="py-4">
            {searchState ? (
              <div id="datatable-basic_filter" className="px-3 pb-3">
                <label>
                  Search:
                  <MySearch
                    searchState={searchState}
                    placeholder="search by address"
                    {...props.searchProps}
                  />
                </label>
              </div>
            ) : null}
            <BootstrapTable
              remote={{ pagination: true }}
              {...props.baseProps}
              bootstrap4={true}
              pagination={paginationFactory({
                ...initialOptions,
                pageButtonRenderer,
                page,
                sizePerPage,
                totalSize,
              })}
              bordered={false}
              onTableChange={onTableChange}
              rowEvents={rowEvents ? rowEvents : null}
              rowStyle={rowStyle ? rowStyle : null}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default CustomTable;
