import Profile from "views/pages/Profile";
import CustomLogin from "views/pages/login";
import Profiles from "views/pages/examples/Profile";
import LicenseActivation from "views/pages/LicenseActivation";

import { Unlock } from "react-iconly";

const routes = [
  {
    path: "/license-activation",
    name: "License Activation",
    icon: Unlock,
    component: LicenseActivation,
    layout: "/user",
  },
  {
    path: "/login",
    name: "",
    component: CustomLogin,
    layout: "/auth",
  },
  {
    path: "/profile/:id",
    name: "",
    miniName: "",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/profiles",
    name: "",
    miniName: "",
    component: Profiles,
    layout: "/user",
  },
];

export default routes;